<template>
  <div class="repair-suc-wrapper">
    <div class="suc-icon">
      <i class="iconfont iconcheck-active"></i>
    </div>
    <p class="tip-box">提交成功</p>
    <div class="qrcode-box">
      <p>提交成功，正在返回列表...</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CheckSuc',
    mounted () {
      setTimeout(() => {
        this.$router.push({
          path: '/maintain'
        })
      }, 2000)
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/mixin";
  .repair-suc-wrapper{
    .suc-icon{
      text-align: center;
      i{
        font-size: 5rem;
        color: #51C513;
      }
    }
    p.tip-box{
      text-align: center;
    }
    .qrcode-box{
      margin-top: 3rem;
      margin-bottom: 1rem;
      p{
        font-size: 0.7rem;
        color: #666666;
        text-align: center;
      }
      .qrcode-img{
        width: 10rem;
        height: 9rem;
        margin: 0.5rem auto;
        background-color: #f1f1f1;
      }
    }
    .btn-box{
      width: 100%;
      margin-top: 0.5rem;
      text-align: center;
      button{
        height: 2.2rem;
        border-radius: 2rem;
        font-size: 0.8rem;
        color: white;
        padding: 0 1.5rem;
      }
    }
  }
</style>
